.partners {
  .partner {
    .partner-content {
      .partner-description {
        .field-name-body {
          p {
            color: $brown;
          }
        }
      }

      .partner-link {
        i {
          color: $brown_light;
        }

        a {
          color: $brown_light;
        }
      }
      
      .arrow {
        background: $brown_light;
      }
    }
  }
}
