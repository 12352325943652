input[type="password"],
input[type="text"],
input[type="email"] {
  &:not(:placeholder-shown) {
    border-color: $green;
    color: $green;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: $green!important;
  }
}

textarea {
  &:not(:placeholder-shown) {
    border-color: $green;
    color: $green;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: $green!important;
  }
}

.btn,
input[type="submit"] {
  background: $green;

  &:hover {
    background: $green_light;
  }
}

.content-webform {
  .content-container-webform {
    .links {
      a {
        color: $brown_light;
      }
    }
  }
}
