.view-faq-wng {
  .view-filters {
    .views-exposed-form {
      #edit-category-term-wrapper {
        label {
          color: $brown;
        }

        .form-item {
          &.form-item-category-term {
            a {
              &:hover,
              &.active {
                color: $blue_light;
              }
            }

            #edit-category-term-all {
              a {
                &.active,
                &:hover {
                  color: $brown_light;
                }
              }
            }
          }
        }
      }
    }
  }

  .view-content {
    h3 {
      color: $brown;
      border-bottom: 1px solid $brown;
    }
  }
}
