.main-banner {
  .slide-item {
    .content {
      .left {
        .title {
          color: $brown;
        }

        .field-name-field-wngh-banner-description {
          color: $brown;
        }
      }
    }
  }

  .icons {
    .field-items {
      .field-item {
        .fc-feature {
          .icon {
            svg {
              fill: url(#icon-gradient) $brown_light;
            }
          }

          .icon-label {
            color: $brown;
          }
        }
      }
    }
  }

  .slick-dots {
    li {
      background-color: $green;
    }
  }
}
