body.node-type-wngv-homepage {
  .square-blocks {
    .field-name-field-wngv-home-square-blocks {
      & > .field-items {
        & > .field-item {
          .square-block {
            .inner {
              h2 {
                margin-top: 0;
              }

              h2 a {
                color: $brown;
              }
            }
          }
        }
      }
    }
  }

  .textvsimage-block {
    .textvsimage {
      .content {
        background: $brown;

        a.btn-vs {
          &:hover {
            color: $brown;

            svg {
              fill: $brown;
            }
          }
        }
      }
    }
  }

  .about {
    h3 {
      color: $brown;
    }

    .description {
      &:after {
        background-color: $brown_light;
      }
    }

    .circle-cards {
      .field-name-field-wngh-home-about-us {
        & > .field-items {
          & > .field-item {
            a.card-link {
              .circle-card {
                .content {
                  background: rgba(163, 81, 33, 0.8);

                  .btn-go {
                    background: $green;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
