$size-x-small         : 320px; // 24
$size-small-1         : 490px; // 48 => 1024
$size-small           : 491px; // 48 => 1024
$size-medium-1        : 768px; // 48
$size-medium          : 769px; // 48
$size-medium-large-1  : 1023px;
$size-medium-large    : 1024px;
$size-large-1         : 1199px;
$size-large           : 1200px;

// Typo !important
$base__height: 16px;
$menu_mobile_height: 70px;

// Colors.
$blue: #252D5C;
$blue_light: #06A7E0;
$blue_grey: #A4A8BB;
$blue_medium: #F5FCFE;
$blue_form: #80CBF2;
$grey: #4F4F4F;
$brown: #A35121;
$green: #51AF31;
$yellow: #F6A20E;
$orange: #EC671D;
$orange_cta: #FFA400;
$red: #E61F2B;
$white: #FFFFFF;
$blue_medium: #EAF6FB;
$white_light: #FEFEFE;
$white_medium: #F5F5F5;
//$il_blue: #42CBF9;  //@todo: We change the hexa code to avoid the change vars in all css sheets -> Waiting for Roy validation
$il_blue: #252D5C;

// Kafevend
$brown_light: #D5B299;
$green_light: #A8CC6D;

// Become new customer.
$orange_new: #FFA400;

// Phone FR.
$purple_phone: #A50F78;

// Grey.
$grey_label: #7F7F7F;
$grey_value: #B2B2B2;
$grey_line: #EBEBEB;
$grey_transparent: #ADADB5;
$grey_calendar: #D8D8D8;
$grey_disabled: #E4E3E3;
$grey_light: #F8F8F8;
$grey_bullet: #9FA5BA;
$grey_footer: #EEEEEE;

// Career corpo.
$bg_blue_grey: #F7FCFE;

// Fonts.
$main-font: 'Quicksand';
$main-font-ru: 'Roboto';
$main-font-il: 'FbSpacer';
$fallback-font: 'Helvetica';

// IL specific brown
$brown_dark: #893F1A;
$brown2: #703716;
$brown2_dark: #3D1E0C;
