.tabs {
  &.tabs-admin {
    ul {
      li {
        a {
          border-bottom: 1px solid $brown;
        }
      }
    }
  }
}
