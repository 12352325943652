.node-type-wngv-web-product {
  .top-product {
    .sse-product-img-variant-addr {
      .sse-product-img {
        .slick-dots {
          li {
            &.slick-active {
              opacity: 1;
              border: 2px solid $brown_light;
            }
          }
        }
      }
    }

    .sse-product-texts {
      .product-features {
        & > div.product-feature {
          svg {
            fill: $brown;
          }

          span {
            color: $brown;
          }
        }
      }

      .field-collection-container {
        .field-name-field-pp-characteristic-list {
          & > .field-items {
            & > .field-item {
              .field-collection-view {
                .field-name-field-ppcl-title {
                  color: $brown;
                }
                .field-name-field-ppcl-list-item {
                  .field-item {
                    &:before {
                      background: $brown_light;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// PRODUCT PAGE TEASER
article.node-wngv-web-product {
  &.node-teaser {

    h4 {
      color: $brown;

      a {
        color: $brown;

        &:hover {
          color: $brown_light;
        }
      }
    }
  }
}
// END OF PRODUCT TEASER
