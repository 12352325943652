footer {

  #block-edensprings-website-ng-blocks-website-ng-v3-footer {
    .footer-top {
      .separator {
        background: $brown;
      }
    }

    .footer-bottom {
      ul {
        li {
          a {
            &:hover {
              color: $green;
            }
          }
        }
      }
    }
  }
}
