.region-header-top {
  #block-edensprings-website-ng-blocks-website-ng-v3-header {
    #header-content {

      & > .container {
        .header-select {
          span {
            border-color: $brown;
            color: $brown;
            background-image: url(../images/select-arrow-kfvd.png);
            text-transform: uppercase;
          }

          .item-list-container {
            ul {
              li {
                a {
                  color: $brown;

                  &:hover {
                    color: $brown_light;
                  }
                }
              }
            }
          }

          &.language-select {
            .item-list {
              padding: 7px 0 0;
              border-radius: 0;
            }
          }

          &.bar {
            .simplebar-track.vertical {
              .simplebar-scrollbar {
                &:before {
                  background: $brown_light;
                }
              }
            }
          }
        }

        // MAIN MENU.
        .menu {
          // LEVEL 1 MAIN MENU
          & > ul {
            & > li {
              & > span,
              & > a {
                color: $brown;

                &.active,
                &:hover {
                  color: $brown_light;
                }
              }

              div.submenu {
                // LEVEL 2 MAIN MENU
                ul {
                  li {
                    a {
                      color: $brown;

                      &.active,
                      &:hover {
                        color: $brown_light;
                      }
                    }
                  }
                }
              }

              &.active {
                & > span {
                  color: $brown_light;
                }
              }
            }
          }
        }
      }
    }
  }

  .user {
    color: $brown;

    a {
      color: $brown_light;
    }
  }

  .user {
    a.logged {
      color: $brown_light;
    }
  }

  .new_user {
    a {
      background: $green;
      color: $white;

      &:hover {
        background: $green_light;
      }
    }
  }
}
