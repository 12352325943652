a {
  &:hover {
    color: $green_light;
  }
}

select {
  background-image: url(../images/select-arrow-kfvd.png);
}

button.ui-btn {
  color: $green;
  border: 1px solid $green;

  &:hover {
    box-shadow: 1.5px 1.5px 0 $green;
  }

  &.blue {
    background: $green;
    &:hover {
      box-shadow: 1.5px 1.5px 0 $green_light;
    }
  }

  &:disabled {
    background: $grey_disabled;
  }
}


.cke_editable,
.field {
  ul {
    li {
      &:before {
        color: $green;
      }
    }
  }

  ol {
    li {
      color: $green;
    }
  }

  blockquote {
    border-color: $green;
  }
}
