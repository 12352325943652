// MOBILE < 768
@media only screen and (max-width: $size-medium-1) {
  .region-header-top {
    #block-edensprings-website-ng-blocks-website-ng-v3-header {
      #header-content {
        & > .container {
          .burger-icon,
          .burger-icon::before,
          .burger-icon::after {
            background-color: $brown;
          }

          .menu {
            background: $brown;

            & > ul {
              & > li {
                & > span,
                a {
                  color: $white;

                  &.active,
                  &:hover {
                    color: $white,
                  }
                }

                &.active {
                  background: $white;

                  span,
                  a {
                    color: $brown_light;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
