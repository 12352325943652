.careers {
  .pager {
    a {
      background: $brown;

      &:hover {
        background: $brown_light;
      }
    }
  }
}

.view-join-us {
  &:before {
    background: $brown_light;
  }
}

.job-offer {
  .content-short {
    h3 {
      color: $brown;
    }
  }


  .job-offer-body {
    .body-padding {
      .lists {
        & > div {
          .subtitle {
            color: $brown;
          }
        }
      }
    }
  }

  .btn-group {
    .toggle-btn {
      color: $brown;

      &:hover {
        color: $brown_light;
      }
    }
  }
}

.spontaneous-app {
  h3 {
    color: $brown;
  }
}

// FORM ///
.career-form {
  & > div,
  & > form {
    .webform-component--rgpd {
      a {
        color: $green;
      }
    }

    .webform-component-checkboxes {
      input[type="checkbox"] {
        & + label {
          &:before {
            border-color: $brown;
          }
        }

        &:checked {
          & + label {
            &:before {
              border-color: $brown;
            }

            &:after {
              background: $brown;
            }
          }
        }
      }
    }
  }

  .links {
    a {
      color: $brown_light;
    }
  }
}
