.node-type-wngv-offers {
  .field-name-field-wngv-offers-offer-ref {
    & > .field-items {
      & > .field-item {

        h2 {
          a {
            color: $brown;
          }
        }
      }
    }
  }
}
