body:not(.page-digital-onboarding) {
  .page {
    .breadcrumb {
      .breadcrumbs {
        color: $brown;
        border-bottom: 1px solid $brown;

        span.child {
          a {
            color: $brown;
          }
        }
      }
    }
  }
}
