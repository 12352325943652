.node-type-wngv-business-line {
  .introduction {
    .field-name-field-wngv-tvip-subtitle {
      color: $brown;
    }

    .field-name-field-wngv-tvip-sub-description {
      &:after {
        background: $brown_light;
      }
    }
  }

  .web-products {
    h3 {
      color: $brown;
    }

    // List of products
    .field-name-field-wgnv-bl-web-products {
      .slick-slider {
        .slick-arrow {
          color: $brown;

          &:hover {
            color: $brown_light;
          }
        }
      }
    }
  }
}
