.view-wng-blogs {
  .view-filters {
    .views-exposed-form {
      #edit-blog-categ-wrapper {
        label {
          color: $brown;
        }
      }
    }
  }

  .item-list {
    .pager {
      li.pager-current,
      li a:hover {
        background: $brown_light;
      }
    }
  }
}

.node-type-blog {
  .content.container {
    .top-backlink {
      i {
        color: $brown_light;
      }

      a {
        color: $brown;

        &:hover {
          color: $brown_light;
        }
      }
    }

    .field-name-field-blog-long-title {
      color: $brown;
    }

    .tags {
      .field-name-field-blog-category {
        .field-item {
          border-color: $brown;
          color: $brown;
        }
      }
    }
  }
}
