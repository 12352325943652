// MOBILE < 768
@media only screen and (max-width: $size-medium-1) {
  .partners {
    .partner {
      .partner-content {
        background: $brown_light;

        .partner-link {
          i, a {
            color: $brown;
          }
        }

        .arrow {
          background: $brown;
        }
      }
    }
  }
}

