.springs-map {
  .field-name-field-wngv-springs-spring-ref {
    & > .field-items {
      & > .field-item {
        .spring {
          .spring-file {
            i, a {
              color: $brown_light;
            }
          }

          &.active {
            border-color: $brown_light;
          }
        }
      }
    }
  }

  #map {
    .gmap-popup {
      span.title {
        color: $brown;
      }

      span.gmap-btn {
        background: $green;

        &:hover {
          background: $green_light;
        }
      }
    }
  }
}
