#block-edensprings-website-ng-commonform-website-ng-commonform {
  #gaq-btn {
    background-color: $brown_light;
    color: $brown;
  }

  .webform-wrapper {
    &.default {
      ul.tab {
        li {
          background-color: rgba(163, 81, 33, 0.25);

          &.active {
            background-color: $brown;

            &:after {
              border-top-color: $brown;
            }
          }

          &:hover {
            background-color: $brown;
          }
        }
      }

      ul.webform-list {
        & > li {
          form {
            .form-item {
              select {
                &.blue {
                  color: $green;
                }
              }

              &.webform-component--rgpd {
                a {
                  color: $brown;
                }
              }

              &.webform-component-checkboxes {
                input[type="checkbox"] {
                  & + label {
                    &:before {
                      border-color: $brown;
                    }
                  }

                  &:checked {
                    & + label {
                      &:before {
                        border-color: $brown;
                      }

                      &:after {
                        background: $brown;
                      }
                    }
                  }
                }
              }
            }

            #postal_code_autocomplete {
              ul {
                border-color: $brown_light;
                li {
                  &:hover {
                    color:$brown_light;
                  }
                }
              }
            }
          }
        }

        .links {
          a {
            color: $brown_light;
          }
        }
      }
    }

    // Sticky form for Template Landing.
    &.sticky {
      background: $brown_light;

      div.title-container {
        color: $brown;
      }

      ul.tab {
        margin: 0 0 10px;
        padding: 0;
        display: flex;
        justify-content: space-between;

        li {
          background-color: rgba(163, 81, 33, 0.25);

          &.active {
            background-color: $brown;

            &:after {
              border-top-color: $brown;
            }
          }

          &:hover {
            background-color: $brown;
          }
        }
      }

      ul.webform-list {
        & > li {
          form {
            .form-item {
              select {
                &.blue {
                  color: $green;
                }
              }

              &.webform-component-select {
                &:after {
                  color: $green;
                }
              }

              &.webform-component--rgpd {
                a {
                  color: $brown;
                }
              }
            }
          }
        }
      }
    }
  }
}

.phone-number {
  a.phone-link {
    color: $brown;
  }
}
