#block-edensprings-website-ng-blocks-website-ng-v3-cta {
  .floating-buttons {
    & > div {
      a {
        &.get-an-offer {
          background: $green;
        }
      }
    }
  }
}
