h1,
.h1,
.extrabold {
  color: $brown;
}

h2,
.h2,
.bold {
  color: $brown;
}

.material-icons {
  &.green {
    color: $green;
  }
  &.orange {
    color: $orange;
  }
  &.red {
    color: $red;
  }
  &.blue_light {
    color: $blue_light;
  }
}
