.page-help {
  .content-help {
    h2 {
      &:after {
        background: $brown_light;
      }
    }

    .top-blocks,
    .bottom-blocks {
      & > div {
        .help-block {

          a {
            color: $brown;
          }

          span.plus,
          a.btn-custom {
            background: $brown_light;
          }


          svg {
            g#ADVISOR {
              stroke: $brown;
            }

            g#ORDERS-2 path {
              stroke: $brown;
            }
          }

          span.name {
            color: $brown;
          }

          &.subscribe-online {
            a.btn-custom {
              background: $orange_cta;
            }
          }

          &.manage-account,
          &.customer-service {
            span.plus {
              background: $green;
            }
          }
        }
      }
    }
  }

  .bottom-open {
    #customer {
      .call-center-phone {
        color: $brown;

        div.phone-number {
          a {
            color: $brown;

            &:hover {
              color: $brown_light;
            }
          }
        }
      }

      form {
        .webform-component--rgpd {
          a {
            color: $brown;
          }
        }

        .webform-component-checkboxes {
          input[type="checkbox"] {
            & + label {
              &:before {
                border-color: $brown;
              }
            }

            &:checked {
              & + label {
                &:before {
                  border-color: $brown;
                }

                &:after {
                  background: $brown;
                }
              }
            }
          }
        }
      }

      .links {
        a {
          color: $brown_light;
        }
      }
    }
  }
}
