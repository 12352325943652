.textvsimage {
  .content {
    h2 {
      a {
        color: $brown;
      }
    }
    
    a.btn-vs {
      background: $brown;

      &:hover {
        background: $brown_light;
      }
    }
  }
}

// Text VS Image (Page)
.node-type-wngv-tvip {
  .introduction {
    .field-name-field-wngv-tvip-subtitle {
      color: $brown;
    }

    .field-name-field-wngv-tvip-sub-description {
      &:after {
        background: $brown_light;
      }
    }
  }
}
