.tabs-admin {
  ul {
    border-color: $brown;

    li {
      a {
        color: $brown;
        border-color: $brown;

        &.active,
        &:hover {
          background: $brown;
          border-color: $brown;
        }
      }
    }
  }
}
